import "antd/lib/message/style";
import _message from "antd/lib/message";
import "antd/lib/notification/style";
import _notification from "antd/lib/notification";

/**
 * app 初始化一些逻辑
 */
import request from 'axios';
import get from 'lodash/get';
import values from 'lodash/values';
var netLan = {
  'common.network_error': {
    zh_CN: '网络异常',
    en_US: 'Network Error'
  },
  'common.network_error_check': {
    zh_CN: '网络异常，请检查您的网络',
    en_US: 'Network lost, please check your network'
  },
  'common.request_error': {
    zh_CN: '请求异常',
    en_US: 'Request fail'
  },
  'common.request_error_check': {
    zh_CN: '请求异常，请稍后再试',
    en_US: 'Request fail, please try again later'
  }
}; // eslint-disable-next-line

var basename = '/'; // 下面这样注释不能删除,参见webpack.config.js

basename='/';

request.defaults.baseURL = basename + 'api/';
request.defaults.withCredentials = true; // 数据错误处理

request.interceptors.response.use(function (res) {
  return res;
}, function (error) {
  // 网络异常提示
  if (error.message === 'Network Error') {
    _notification.destroy();

    _notification.open({
      message: netLan['common.network_error'][window.locale],
      description: netLan['common.network_error_check'][window.locale],
      duration: null
    });

    return Promise.reject(error);
  } // 尝试读取错误消息 多种错误消息格式返回兼容


  var msg = get(error, 'response.data.msg', '') + values(get(error, 'response.data.errors', [])).join('');

  if (msg) {
    _message.destroy();

    _message.error(msg, 3); // 3s


    return Promise.reject(error);
  } // 其他错误提示


  _notification.destroy();

  _notification.open({
    message: netLan['common.network_error'][window.locale],
    description: netLan['common.network_error_check'][window.locale],
    duration: null
  });

  return Promise.reject(error);
});