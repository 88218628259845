export default {
  'common.ongoing': 'Active',
  'common.not_started': 'Scheduled',
  'common.finished': 'Expired',
  'common.all': 'All',
  'common.search': 'Search',
  'common.reset': 'Reset',
  'common.operate': 'Action',
  'common.preview': 'Preview',
  'common.delete': 'Delete',
  'common.stop': 'Stop',
  'common.close': 'Close',
  'common.cancel': 'Cancel',
  'common.import_start': 'Start importing',
  'common.import_success': 'Import succeeded',
  'common.import_fail': 'Import failed',
  'common.import_interrupt': 'Import interrupted',
  'common.import_error': 'File import error',
  'common.import_finish': 'Import completed',
  'common.import_retry': 'System error caused the import termination. Please check the file and try again',
  'common.import_ongoing': 'Importing',
  'common.import_template': '{ name } import template',
  'common.select_file': 'Select file',
  'common.detected_record': 'A total of { count } data(s) are detected',
  'common.fail_reason': 'Download failure list and reasons',
  'common.hold_on': 'Please wait......',
  'common.download': 'Click to download',
  'common.text_color': 'Color',
  'common.multi_add_placeholder': 'Press "Enter" to confirm and add multiple items.',
  'common.settings': 'Application setting',
  'common.coupon': 'Amazon coupon',
  'common.product_center': 'Product center',
  'common.customer_center': 'Customer center',
  'common.base_info': 'Basic information',
  'common.activity_name': 'Campaign name',
  'common.activity_time': 'campaign duration',
  'common.timezone': 'Set up time zone',
  'common.activity_name_empty': 'coupon name cannot be blank',
  'common.start_time': 'start time',
  'common.end_time': 'end time',
  'common.promotion_type': 'coupon type',
  'common.product_image': 'Product image',
  'common.product_name': 'Product title',
  'common.product_price': 'product price',
  'common.inventory': 'inventory',
  'common.quantity': 'quantity',
  'common.selling_price': 'selling price',
  'common.use_rule': 'use rule',
  'common.select_product': 'select product',
  'common.all_collection': 'All collection',
  'common.all_status': 'all status',
  'common.status': 'Status',
  'common.onsale': 'available',
  'common.offsale': 'unavailable',
  'component.name_label': 'product name/label',
  'common.product': 'product',
  'common.price': 'price',
  'common.note': 'Note',
  'common.no_data': 'No data found',
  'common.confirm': 'confirm',
  'common.product_selected': '{count} product(s) selected',
  'common.empty_limit': 'cannot be blank',
  'common.select_time': 'please select activity time',
  'common.empty_number': 'please enter',
  'common.remain_empty': 'please enter one-to-seven-digit whole numbers',
  'common.loadmore': 'load more',
  'common.unpublished': 'unpublished',
  'common.untracked_inventory': 'untracked inventory',
  'common.activity_quit': 'quit activity',
  'common.product_count': '{count} product(s)',
  'common.open': 'Open',
  'common.today': 'Today',
  'common.yesterday': 'Yesterday',
  'common.past_seven_days': 'Past seven days',
  'common.start_date': 'Start date',
  'common.end_date': 'End date',
  'common.batch_delete': 'batch delete',
  'common.delete_tip': 'unretrievable after deletion, please think twice before proceeding!',
  'common.delete_detail_confirm': 'Delete {name}?',
  'common.delete_confirm': 'Are you sure you want to delete?',
  'common.operate_success': 'succeeded',
  'common.button_link': 'go to:',
  'common.button_link_title': 'Go to:',
  'common.toggle_to_asin': 'toggle to ASIN',
  'common.toggle_to_link': 'toggle to link',
  'common.amazon_website': 'Amazon website',
  'component.product_select': 'select product',
  'common.published': 'published',
  'component.empty_product': 'empty',
  'component.create_product_link': 'add products',
  'common.select_all': 'select all',
  'component.selected_product': '{number} product selected',
  'common.add': 'add',
  'common.product_info': 'product information',
  'common.coupon_code': 'coupon code',
  'common.coupon_code_empty': 'please enter coupon code',
  'common.save_success': 'successfully saved',
  'common.indefinite': 'Unlimited',
  'common.activity_participated': 'Included in other campaigns',
  'common.delete_success': 'Deleted',
  'common.network_error': 'Network Error',
  'common.setting_success': 'Setup succeeded',
  'common.modify_success': 'Modified',
  'common.set_code': 'set code',
  'common.table_header_setting_tip': 'Please select at least 5 header fields to be displayed. Drag to change the order.',
  'common.recovery_setting': 'recover default setting',
  'common.compile': 'Edit',
  'common.email': 'Email',
  'common.allow_oversold': 'Overselling allowed',
  'common.coupon_selected': '{count} coupon code selected',
  'common.coupon_input': 'coupon name/code',
  'common.pagination_total': '{total} {title}',
  'common.pagination_record': 'Record(s)'
};