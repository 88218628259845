export default {
  "coupon.coupon": "优惠券",
  "coupon.manage": "优惠券管理",
  "coupon.file_upload": "导入文件",
  "coupon.add_coupon": "新增优惠券",
  "coupon.code_input": "优惠券名称/优惠码",
  "coupon.type_all": "所有优惠券类型",
  "coupon.type1": "Preferential",
  "coupon.type2": "Unrestricted",
  "coupon.type3": "Exclusive",
  "coupon.name": "优惠券名称",
  "coupon.code": "优惠码",
  'coupon.type': '优惠券类型',
  'coupon.expired': "有效期",
  "coupon.discount": "折扣额度",
  "coupon.discount_type": "折扣额度类型",
  "coupon.email_count": "已获取邮箱数",
  "coupon.remain_code": "可发放数量",
  "coupon.product_range": "商品范围",
  "coupon.status": "状态",
  "coupon.type_discount": "折扣券",
  "coupon.type_voucher": "代金券",
  "coupon.all_product": "全场",
  "coupon.part": "部分商品（{num}件）",
  "coupon.delete_title": "确定删除该优惠码？",
  "coupon.delete_tip": "删除后此优惠码将不作领取展示，请慎重操作!",
  "coupon.stop_title": "确定终止该活动？",
  "coupon.stop_tip": "终止后此优惠码将不作领取展示，请谨慎操作",
  "coupon.cancel_upload": "优惠券上传取消",
  "coupon.cancel_confirm": "优惠券上传尚未完成，确定要现在取消吗？",
  "coupon.bulk_import": "批量上传优惠券",
  "coupon.unit": "条",
  "coupon.format_limit": "仅支持xls,xlsx格式文件",
  "coupon.size_limit": "文件大小不超过2M",
  "coupon.import_template": "批量上传优惠券模版",
  "coupon.rule_require": "请按规范填写表格，仅支持xls,xlsx格式文件",
  "coupon.cover_duplicated": "覆盖重复的优惠券",
  "coupon.cover_tip": "当导入coupon code已存在时，该条导入失败",
  "coupon.time_unlimited": "长期有效",
  "coupon.remain_count": "可发放数量",
  "coupon.piece": "张",
  "coupon.preferential_rule": "优惠规则",
  "coupon.application_scope": "适用范围",
  "coupon.all_product_select": "选择全场商品",
  "coupon.all_product_desc": "所有商品可使用优惠码",
  "coupon.part_product": "选择部分商品",
  "coupon.part_product_desc": "已选 {num} 件商品",
  "coupon.continue_add": "继续添加商品",
  "coupon.quantity_unlimit": "不限数量",
  "coupon.receive_emails": "此优惠券已获取邮箱",
  "coupon.reduce": "减",
  "coupon.discount_input": "支持输入5-80范围内正整数，输入框不能为空",
  "coupon.voucher_input": "输入1-7位正数，支持小数点后两位，不能为空",
  "coupon.product_limit": "至少选择一个商品"
};