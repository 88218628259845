export default {
  "products.center": "Product center",
  "products.jump_desc": "Add a link to Product Page, direct traffic to your designated page",
  "products.add": "Add product",
  "products.name": "Product title",
  "products.coupon_status": "Coupon code status",
  "products.code_used": "Configured",
  "products.code_not_used": "Not configured",
  "products.status": "Product status",
  "products.all_status": "All product status",
  "products.all_status_coupon": "All codes status",
  "products.url_illegal": "Enter a valid URL"
};