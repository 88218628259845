import { observable } from 'mobx';
export default observable({
  noticeObj: [{
    title: 'customer.recall_user',
    desc: 'customer.recall_user_tip',
    checked: false,
    key: 'recall_user',
    disabled: false
  }],
  modalHtml: '<html><style>.loading{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)}</style><p class="loading">Loading...</p></html>',
  modalVisible: false
});