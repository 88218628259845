import _AsyncComponent from "shoplazza-sdk/lib/async-component";
export default (function (_ref) {
  var _routes$filter$0$rout;

  var routes = _ref.routes,
      models = _ref.models,
      messages = _ref.messages,
      locale = _ref.locale;

  (_routes$filter$0$rout = routes.filter(function (r) {
    return r.path === '/';
  })[0].routes).push.apply(_routes$filter$0$rout, [{
    path: '/products',
    exact: true,
    component: _AsyncComponent(function () {
      return import('./components/List');
    })
  }]);

  Object.assign(messages, require('./locale/' + locale).default);
});