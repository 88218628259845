import React from 'react';
import PropTypes from 'prop-types';

var NoMatch = function NoMatch(_ref) {
  var location = _ref.location;
  return React.createElement("div", null, React.createElement("h3", {
    style: {
      display: 'none'
    }
  }, "No match for", React.createElement("code", null, location.pathname)));
};

NoMatch.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};
export default NoMatch;