export default {
  "customer.email": "Recovery email",
  "customer.manage": "Managing customers",
  "customer.pending": "Pending",
  "customer.sending": "Sending",
  "customer.sent": "Sent",
  "customer.failed": "Failed",
  "customer.resending": "Resending",
  "customer.sent_again": "Resent successfully",
  "customer.batch_send": "Bulk sending",
  "customer.email_send": "Sent successfully",
  "customer.note_send": "Notes added successfully",
  "customer.recall_user": "Recover customers",
  "customer.recall_user_tip": "For customers who do not use the coupons after receiving them, you can recover by sending an email to remind them.",
  "customer.set_email": "Set up email",
  "customer.note": "Bulk adding notes",
  "customer.keyword_input": "Coupon name/coupon code/email/notes"
};