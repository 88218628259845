export default {
  'coupon.coupon': 'coupon',
  'coupon.manage': 'Coupon management',
  'coupon.file_upload': 'Import ',
  'coupon.add_coupon': 'Add New Coupon',
  'coupon.code_input': 'Coupon name / Coupon code',
  'coupon.type_all': 'All coupon types',
  'coupon.type1': 'Preferential',
  'coupon.type2': 'Unrestricted',
  'coupon.type3': 'Exclusive',
  'coupon.name': 'Coupon name',
  'coupon.code': 'Coupon code',
  'coupon.type': 'Coupon type',
  'coupon.expired': 'Valid date',
  'coupon.discount': 'Discount ',
  'coupon.discount_type': 'Promotion types',
  'coupon.email_count': 'Number of emails acquired',
  'coupon.remain_code': 'Remaining',
  'coupon.product_range': 'Scope of product',
  'coupon.status': 'Status',
  'coupon.type_discount': 'Discount coupon',
  'coupon.type_voucher': 'Voucher',
  'coupon.all_product': 'all products',
  'coupon.part': 'Selected {num} products',
  'coupon.delete_title': 'Are you sure to delete coupon codes?',
  'coupon.delete_tip': "Coupon codes won't be displayed as collectable status once deleted. Proceed with caution.",
  'coupon.stop_title': 'Terminate campaign?',
  'coupon.stop_tip': "Coupon codes won't be displayed as collectable status once deleted. Proceed with caution.",
  'coupon.cancel_upload': 'Cancel coupon uploading',
  'coupon.cancel_confirm': 'Are you sure to terminate coupon uploading?',
  'coupon.bulk_import': 'Bulk coupon uploading',
  'coupon.unit': 'Unit',
  'coupon.format_limit': 'XLS, XLSX files are accepted.',
  'coupon.size_limit': 'File size limited to 2MB.',
  'coupon.import_template': 'Bulk uploading coupon templates.',
  'coupon.rule_require': 'Follow guideline to fill out the form. XLS, XLSX files are accepted.',
  'coupon.cover_duplicated': 'Overwrite duplicated coupons',
  'coupon.cover_tip': "Duplicated coupon code can't be imported.",
  'coupon.time_unlimited': 'Long-term effective',
  'coupon.remain_count': 'Available distributable items',
  'coupon.piece': 'coupons',
  'coupon.preferential_rule': 'Discount rules',
  'coupon.application_scope': 'Applicable conditions',
  'coupon.all_product_select': 'Select all',
  'coupon.all_product_desc': 'Coupon code applicable to all products',
  'coupon.part_product': 'Select products',
  'coupon.part_product_desc': '{num} product(s) selected',
  'coupon.continue_add': 'Continue to add products',
  'coupon.quantity_unlimit': 'Unlimited quantity',
  'coupon.receive_emails': 'This coupon is registered with this email address.',
  'coupon.reduce': 'minus',
  'coupon.discount_input': 'A positive integer, range from 5-80, must be entered.',
  'coupon.voucher_input': 'A positive number with 1-7 digits and two decimals must be entered.',
  'coupon.product_limit': 'Select at least one product'
};