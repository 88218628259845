export default {
  "products.center": "商品中心",
  "products.jump_desc": "设置商品详情页按钮跳转的链接，可将流量引到指定页面",
  "products.add": "添加商品",
  "products.name": "商品名称",
  "products.coupon_status": "Coupon Code 配置状态",
  "products.code_used": "已配置",
  "products.code_not_used": "未配置",
  "products.status": "商品状态",
  "products.all_status": "全部商品状态",
  "products.all_status_coupon": "全部Code状态",
  "products.url_illegal": "请输入正确的URL"
};