import { observable, observe } from 'mobx';
var defaultValues = {
  backStatus: false,
  // 是否显示返回按钮
  backClick: function backClick() {},
  // 返回点击事件
  breadcrumbName: '',
  // 返回文案
  isBreadCrumbDirty: false,
  // model 是否已经修改
  onConfirmSave: function onConfirmSave() {},
  // 保存按钮事件回调
  onConfirmCancel: function onConfirmCancel() {},
  // 取消更改按钮事件回调
  showButtonsWhenDirty: true,
  // 是否显示保存按钮
  raw: '',
  push: function push() {},
  extraButtons: [
    /*{
    name:'btn1',
    children:"普通按钮"
    },{
    name:'btn2',
    type:"primary",
    children:"首选按钮",
    disabled:true
    }*/
  ],
  onExtraButtonClick: function onExtraButtonClick() {}
};
var model = observable(defaultValues);

if (window.parent !== window) {
  ['backStatus', 'breadcrumbName', 'isBreadCrumbDirty', 'showButtonsWhenDirty', 'raw', 'extraButtons'].forEach(function (n) {
    observe(model, n, function (change) {
      parent.postMessage({
        type: 'shoplazza',
        name: n,
        value: /raw|extraButtons/g.test(n) ? JSON.stringify(change.newValue) : change.newValue // raw 字段需要序列化

      }, '*');
    });
  });
}

window.addEventListener('message', function (event) {
  var params = event.data || {};
  if (params.type !== 'shoplazza') return;
  typeof model[params.name] === 'function' && model[params.name](params);
}, false);

model.push = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  parent.postMessage({
    type: 'shoplazza',
    name: 'push',
    value: JSON.stringify(args)
  }, '*');
};

model.open = function (value) {
  parent.postMessage({
    type: 'shoplazza',
    name: 'open',
    value: value
  }, '*');
};

export default model;