export default {
  'common.ongoing': '进行中',
  'common.not_started': '待开始',
  'common.finished': '已结束',
  'common.all': '全部',
  'common.search': '搜索',
  'common.reset': '重置',
  'common.operate': '操作',
  'common.preview': '预览',
  'common.delete': '删除',
  'common.stop': '结束',
  'common.close': '关闭',
  'common.cancel': '取消',
  'common.import_start': '开始导入',
  'common.import_success': '导入成功',
  'common.import_fail': '导入失败',
  'common.import_interrupt': '导入中断',
  'common.import_error': '文件导入出错',
  'common.import_finish': '导入完成',
  'common.import_retry': '系统错误导致导入终止，请检查文件重新尝试',
  'common.import_ongoing': '正在导入',
  'common.import_template': '{ name }导入模版',
  'common.select_file': '选择文件',
  'common.detected_record': '共检测到 { count } 条数据',
  'common.fail_reason': '下载失败列表及其原因',
  'common.hold_on': '请稍等......',
  'common.download': '点击下载',
  'common.text_color': '文字颜色',
  'common.multi_add_placeholder': '回车确认，可添加多个',
  'common.settings': '应用设置',
  'common.coupon': '亚马逊优惠券',
  'common.product_center': '商品中心',
  'common.customer_center': '顾客中心',
  'common.base_info': '基本信息',
  'common.activity_name': '优惠券名称',
  'common.activity_time': '优惠券有效期',
  'common.timezone': '设置时区',
  'common.activity_name_empty': '优惠券名称不能为空',
  'common.start_time': '开始时间',
  'common.end_time': '结束时间',
  'common.promotion_type': '优惠券类型',
  'common.product_image': '商品图片',
  'common.product_name': '商品名称',
  'common.product_price': '商品价格',
  'common.inventory': '库存',
  'common.quantity': '数量',
  'common.selling_price': '售价',
  'common.use_rule': '使用规则',
  'common.select_product': '请选择商品',
  'common.all_collection': '全部专辑',
  'common.all_status': '全部状态',
  'common.status': '状态',
  'common.onsale': '上架',
  'common.offsale': '下架',
  'component.name_label': '商品名称/标签',
  'common.product': '商品',
  'common.price': '价格',
  'common.note': '备注',
  'common.no_data': '暂无数据',
  'common.confirm': '确定',
  'common.product_selected': '已选择 { count } 件商品',
  'common.empty_limit': '不能为空',
  'common.select_time': '请先选择活动时间',
  'common.empty_number': '请输入最小两位小数的正数',
  'common.remain_empty': '请输入1-7位正整数',
  'common.loadmore': '加载更多',
  'common.unpublished': '未上架',
  'common.untracked_inventory': '未跟踪库存',
  'common.activity_quit': '退出活动',
  'common.product_count': '共 { count } 件商品',
  'common.open': '开启',
  'common.today': '今天',
  'common.yesterday': '昨天',
  'common.past_seven_days': '过去7天',
  'common.start_date': '开始日期',
  'common.end_date': '结束日期',
  'common.batch_delete': '批量删除',
  'common.delete_tip': '删除后将不能找回, 请慎重操作!',
  'common.delete_detail_confirm': '确定删除该{ name }？',
  'common.delete_confirm': '确定删除吗？',
  'common.operate_success': '操作成功',
  'common.button_link': '跳转链接：',
  'common.button_link_title': '跳转链接',
  'common.toggle_to_asin': '切换为 ASIN 跳转',
  'common.toggle_to_link': '切换为链接跳转',
  'common.amazon_website': '亚马逊站点：',
  'component.product_select': '商品选择',
  'common.published': '已上架',
  'component.empty_product': '暂无商品',
  'component.create_product_link': '去添加商品',
  'common.select_all': '全选',
  'component.selected_product': '已选{number}款商品',
  'common.add': '添加',
  'common.product_info': '商品信息',
  'common.coupon_code': '优惠码',
  'common.coupon_code_empty': '请输入优惠码',
  'common.save_success': '保存成功',
  'common.indefinite': '无期限',
  'common.activity_participated': '已参加其他活动',
  'common.delete_success': '删除成功',
  'common.network_error': '网络异常',
  'common.setting_success': '设置成功',
  'common.modify_success': '修改成功',
  'common.set_code': '配置CODE',
  'common.table_header_setting_tip': '拖动更换 优惠券',
  'common.recovery_setting': '恢复默认配置',
  'common.compile': '编辑',
  'common.email': '邮箱',
  'common.allow_oversold': '允许超卖',
  'common.coupon_selected': '已选择 {count} 个优惠码',
  'common.coupon_input': '优惠券名称/优惠码',
  'common.pagination_total': '{total} 条{title}',
  'common.pagination_record': '记录'
};