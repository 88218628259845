export default {
  'settings.title1': '设置详情',
  'settings.title2': '数据中心',
  'settings.remain': 'Coupon Code 剩余可领取 {num} 张',
  'settings.is_open': '是否开启插件',
  'settings.app_mode': '应用模式',
  'settings.app_style': '应用样式',
  'settings.email_enable': '是否开启邮件通知',
  'settings.only_jump_mode': '仅跳转',
  'settings.code_mode': '送 Coupon Code 模式',
  'settings.same_entry_point': '跳转/领取同一入口',
  'settings.other_entry_point': '跳转/领取分开入口',
  'settings.emails_count': '已获取邮箱数',
  'settings.rank_title': '商品领取 code 数量前十排名',
  'settings.rank': '排名',
  'settings.product_name': '商品名',
  'settings.code_count': '领取 Coupon Code 数量',
  'settings.email': '是否开启邮件通知',
  'settings.email_tip': '领取coupon code时同步发送到顾客的邮箱'
};