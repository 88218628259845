import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/**
 *  Loading state of all request
 */
import request from 'axios';
import { observable, extendObservable, toJS } from 'mobx';
import get from 'lodash/get';
var state = new observable({
  get all() {
    var requests = Object.entries(toJS(this));
    return requests.length > 0 && requests.filter(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          k = _ref2[0],
          v = _ref2[1];

      return v === true;
    }).length > 0;
  }

});
request.interceptors.request.use(function (config) {
  if (config.global) return config;
  var url = config.url.replace(config.baseURL, '').replace(/\?.+$/g, '');
  if (!url) return config;

  if (typeof state[url] !== 'undefined') {
    state[url] = true;
  } else {
    extendObservable(state, _defineProperty({}, url, true));
  }

  return config;
});
request.interceptors.response.use(function (res) {
  if (get(res, 'config.global', false)) return res;
  var url = get(res, 'config.url', '');
  url && (state[url.replace(res.config.baseURL, '').replace(/\?.+$/g, '')] = false);
  return res;
}, function (err) {
  if (get(err, 'config.global', false)) return Promise.reject(err);
  var url = get(err, 'config.url', '');
  url && (state[url.replace(err.config.baseURL, '').replace(/\?.+$/g, '')] = false);
  return Promise.reject(err);
});
export default state;