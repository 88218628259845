import Home from './components/Home'; // import Demo from './components/Demo';

import NoMatch from './components/NoMatch';
import loading from './models/loading';
import breadcrumb from './models/breadcrumb';
import config from './models/config';
/**
 * 模块注册文件, routes 属性除[Doc](https://reacttraining.com/react-router/web/api/Route) 定义之外加上 属性:
 *  order: route定义的排序 越小越靠前,默认 0
 */

export default (function (_ref) {
  var routes = _ref.routes,
      models = _ref.models;
  routes.push.apply(routes, [// {
  //   path: '/demo',
  //   order: 800,
  //   exact:true,
  //   component: Demo
  // },
  {
    path: '/',
    order: 1000,
    component: Home,
    routes: [{
      component: NoMatch,
      order: 2000
    }]
  }, {
    component: NoMatch,
    order: 2000
  }]);
  Object.assign(models, {
    loading: loading
  }, {
    breadcrumb: breadcrumb
  }, {
    config: config
  });
});