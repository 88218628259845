export default {
  'settings.title1': 'Setting details',
  'settings.title2': 'Data center',
  'settings.remain': '{num} coupon codes remain to be collected',
  'settings.is_open': 'Activate plugin?',
  'settings.app_mode': 'Application method',
  'settings.app_style': 'Application style',
  'settings.email_enable': 'Activate email notification?',
  'settings.only_jump_mode': 'Only jump to',
  'settings.code_mode': 'Coupon code send mode',
  'settings.same_entry_point': 'Same entrance for linking to or collecting',
  'settings.other_entry_point': 'Separate entrance for linking to or collecting',
  'settings.emails_count': 'Number of emails collected',
  'settings.rank_title': 'Top 10 product of codes collecting',
  'settings.rank': 'ranking',
  'settings.product_name': 'product name',
  'settings.code_count': 'The number of coupon codes collected',
  'settings.email': 'Activate email notifications?',
  'settings.email_tip': "Send to customer's email address when the coupon code is collected"
};