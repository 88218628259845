export default {
  "customer.email": "邮件召回",
  "customer.manage": "顾客管理",
  "customer.pending": "待发送",
  "customer.sending": "发送中",
  "customer.sent": "已发送",
  "customer.failed": "发送失败",
  "customer.resending": "正在重新发送",
  "customer.sent_again": "再次发送成功",
  "customer.batch_send": "批量发送",
  "customer.email_send": "发送成功",
  "customer.note_send": "备注成功",
  "customer.recall_user": "召回用户",
  "customer.recall_user_tip": "对领取优惠券后未使用客户，可以使用召回邮件再次发送领取的优惠券提醒用户使用。",
  "customer.set_email": "设置邮箱",
  "customer.note": "批量备注",
  "customer.keyword_input": "优惠券名称/优惠码/邮箱/备注"
};